/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */




/************** Sunil Css *******************/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

::-webkit-scrollbar, ::-webkit-scrollbar-webkit-scrollbar {
	width: 6px; height: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
	background-color: #d1d1d1;
}

/* @font-face {
  font-family: Poppins-Regular;
  src: url(../assets/css/Poppins-Regular.ttf);
}
*/
body{
  /* font-family: Poppins-Regular; */
  background: #232323!important;
}
a:hover{color: #ff3002!important;}

/* .container-fluid{width:100%; height: auto; float: left; padding: 0px 50px;} */
.header-part {
  width: 100%;
  height: 78px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  background: #323232;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.03);
}
.header-part .logo {
  width: auto;
  max-width: 120px;
  float: left;
  margin-right: 20px;
}
.header-part .logo img{
  max-width: 100%;
}
.logo-mobile{
  max-width: 120px;
  padding: 15px 0px 0px 30px;
  display: none;
}
.logo-mobile img{
  max-width: 100%;
}
.header-part .humbuerger{
  display: none;
}
.logo-menu-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-part .logo-hemburger {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-part .logo-hemburger .menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.menu ul li {
  display: inline-block;
}
.header-part .logo-hemburger .menu ul li a {
  color: #fff;
  text-decoration: none;
  padding: 5px 15px;
}
.header-part .logo-hemburger .menu ul li a:hover {
  color: #ff3002;
  text-decoration: none;
}
.header-part .logo-hemburger .menu ul li a.active{
  color: #ff3002;
}
.header-part .logo-hemburger .menu ul li button {
  color: #fff;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  padding: 5px 15px;
}
.header-part .logo-hemburger .menu ul li button:hover {
  color: #ff3002;
  text-decoration: none;
}
.menu .dropdown-menu {
  z-index: 99999;
  width: 100vw;
  max-width: 900px;
  max-height: 88vh;
  overflow: auto;
  padding: 20px;
  box-shadow: 0 0 12px 4px rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}
.menu .dropdown-menu .megamenu-title {
  border-bottom: solid 1px #575757;
  margin-bottom: 15px;
}
.menu .dropdown-menu .megamenu-title h4{
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #ff3002;
  margin-bottom: 20px;
}
.header-part .dropdown-menu .mega-menu h6{
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: #7e7e7e;
  margin: 10px 0;
  opacity: 0.4;
}
.header-part .dropdown-menu .mega-menu ul li a {
  font-size: 14px;
  font-weight: 500;
  color: #818181;
  padding: 6px 0px;
  display: inline-block;
  text-decoration: none;
}
.header-part .dropdown-menu .mega-menu ul li a:hover {
  color: #ff3002;
  text-decoration: none;
}
.header-part .searchbar {
  width: 100%;
  max-width: 460px;
  float: right;
}
.login-btn-search-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.menu-user-profile .footer-section-a {
  white-space: nowrap;
}
.header-part .searchbar input {
  width: calc(100% - 88px);
  height: 44px;
  background: #232323;
  border: none;
  float: left;
  outline: none;
  color: #fff;
  font-size: 14px;
  padding: 5px 15px;
}
.header-part .searchbar .search-btn{
  width:44px;
  height: 44px;
  border:none;
  background: #4E4E4E;
}
.header-part .searchbar .search-btn.filterbtn{
  background: #ff3002;
}
.header-part .searchbar .search-btn svg {
  max-width: 16px;
  max-height: 16px;
}
.header-part .searchbar .search-btn.filterbtn svg {
  max-width: 26px;
  max-height: 22px;
}

.menu-right-part {
  display: inline-flex;
  align-items: center;
}
.menu-user-profile .user-icon {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #232323;
  border-radius: 50%;
}
.menu-user-profile .user-icon svg {
  max-width: 18px;
  max-height: 18px;
}
.menu-user-profile .dropdown-toggle, .menu-user-profile .dropdown-toggle:hover, .menu-user-profile .dropdown-toggle:focus{
  color: #fff;
  box-shadow: none;
  outline: none;
}
.menu-user-profile .dropdown-menu {
  box-shadow: 0 0 12px 4px rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  min-width: 200px;
}
.menu-user-profile .dropdown-menu a{
  color: #818181;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
}
.menu-user-profile .dropdown-menu a:hover, .menu-user-profile .dropdown-menu a:focus, .menu-user-profile .dropdown-menu a:active{
  color: #fff!important;
  background: #ff3002;
}

.selected-languages{
  width: 100%;
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  text-align: left;
}
.select-language strong{
  font-size: 14px;
  font-weight: 700;
}
.language-mega-menu .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 7px;
}

.language-mega-menu .dropdown-menu{
  width: 100%;
  min-width: 290px;
  padding: 0px;
}
.language-mega-menu .dropdown-menu .megamenu-title {
  border-bottom: solid 1px #575757;
  padding: 20px;
  margin-bottom: 15px;
}
.language-mega-menu .dropdown-menu .megamenu-title h4 {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #ff3002;
  margin-bottom: 0px;
}
.language-mega-menu .dropdown-menu .megamenu-title p{
  font-size: 12px;
  color: #666;
  font-style: italic;
  margin-bottom: 0px;
}

.language-mega-menu .dropdown-menu .all-languages {
  width: 100%;
  max-height: 50vh;
  overflow: auto;
  display: inline-flex;
  flex-flow: wrap;
  gap: 5px;
  padding: 10px;
}
.language-mega-menu .dropdown-menu .all-languages .single-language{
  width: 100%;
  max-width: calc(50% - 3px);
  padding: 6px 5px;
  margin-left: 0px;
  color: #818181;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0px;
  text-decoration: none;
  box-shadow: none;
}

.btn-check+.single-language:hover, .btn-check:checked+.single-language{
  background: #ff3002;
  color: #fff!important;
  border-color: #ff3002;
}

.btn-check+.single-language{
  border: solid #575757;
  border-width: 1px;
  border-radius: 4px!important;
  font-size: 100%;
  font-weight: 700;
  padding: 11px 22px;
  width: 50%;
  list-style-type: none;
  padding: 0px;
  margin: 0;
  display: inline-block;
}
.update-language-btn, .search-filter-footer{
  width: 100%;
  padding: 20px;
  border-top: solid 1px #575757;
}
.update-language-btn button, .search-filter-footer button{
  width: 100%;
  padding: 5px;
  border: none;
  background: transparent;
  border: solid 1px #ff3002;
  color: #ff3002;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.3s;
}
.update-language-btn button:hover, .search-filter-footer button:hover{
  background: #ff3002;
  color: #fff;
}
.main-wrapper {
  display: flex;
  /* position: relative; */
  width: 100%;
  min-height:calc(100vh - 78px);
  float: left;
}
.main-content {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 78px);
  overflow: auto;
  float: left;
  padding: 30px 50px 0px 25px;
}

.siderbar-menu .close-btn{
  display: none;
}
.pro-sidebar.siderbar-menu {
  height: calc(100vh - 78px);
  width: 300px;
  min-width: 300px;
}
.pro-sidebar.siderbar-menu .pro-sidebar-inner {
  background: #2C2C2C;
}
.sidebar-menu .pro-sidebar-inner {
  background: #2C2C2C;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.siderbar-menu .pro-sidebar-layout .download-app {
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
  padding: 35px 30px;
}
.siderbar-menu .pro-sidebar-layout .download-app p{
  font-size: 14px;
  color: #fff;
  margin-bottom: 0px;
}
.adownload-app-links {
  width: 100%;
  height: auto;
  float: left;
  padding-top: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #868686;
}
.adownload-app-links a {
  display: inline-block;
  margin-right: 10px;
}
.adownload-app-links a:last-child {
  margin-right: 0px;
}
.siderbar-menu .pro-menu {
  padding-top: 20px !important;
}
.siderbar-menu .pro-menu .menu-icon {
  width: 25px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}
.pro-menu-item.active span, .pro-menu-item:hover span{
  color: #ff3002;
}
.pro-menu-item.active svg path, .pro-menu-item.active svg, .pro-menu-item:hover svg path, .pro-menu-item:hover svg {
  fill: #ff3002 !important;
}
.siderbar-menu .pro-inner-item {
  padding: 8px 30px 8px 30px!important;
  color: #fff;
}
.siderbar-menu .pro-menu-item {
  position: relative;
}
.siderbar-menu .pro-menu-item.active::before, .siderbar-menu .pro-menu-item:hover::before {
  content: "";
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #ff3002;
}

span.subhead {
  width: 100%;
  display: block;
  padding: 15px 30px 10px 30px;
  font-size: 12px;
  color: #9d9d9d;
}

/****/
.playlist-profile {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}
.playlist-profile .playlist-profile-img {
  flex: 0 0 250px;
  height: 250px;
  object-fit: cover;
}
.playlist-profile .playlist-profile-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.playlist-profile-info{
  width: 100%;
  height: auto;
}
.playlist-profile-info h3{
  font-size: 24px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.playlist-profile-info p{
  font-size: 14px;
  color: #fff;
}

.playlist-songs-list{
  margin-bottom: 40px;
}
.playlist-single-song {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 15px;
  margin-top: 20px;
  border: solid 1px #323232;
  border-radius: 8px;
  transition: all 0.3s;
}
.playlist-single-song:hover{
  background: rgba(124, 124, 124, 0.15);
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.25);
}
.playlist-single-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.playlist-song-img{
  flex: 0 0 80px;
  height: 80px;
  border-radius: 4px;
  object-fit: cover;
}
.playlist-song-img img{
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.song-album-info{
  width: 100%;
  display: flex;
}
.song-name, .artist-overview {
  width: 100%;
  font-size: 14px;
  color: #fff;
  /* min-width: 150px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 300;
}
.song-name{
  font-weight: 700;
}
.remove-from-list{
  min-width: 65px;
}
.remove-from-list button{
  display: none;
}
.playlist-single-song:hover .remove-from-list button{
  display: block;
}
.remove-from-list button svg{
  max-width:30px; max-height: 30px; color: #fff;
}
.time-duration{
  font-size: 14px;
  color: #fff;
  min-width: 60px;
  text-align: center;
}
.playlist-single-song:hover .time-duration{
  display: none;
}
.more-options{
  min-width: 60px;
  display: none;
  text-align: center;
}
.playlist-single-song:hover .more-options{
  display: block;
}
.more-options .dropdown button{
  padding: 0px; outline: none; box-shadow: none;
}
.more-options .dropdown button::after{
  display: none;
}
.more-options .dropdown button svg{
  max-width: 20px;
  max-height: 10px;
}


/****** Hero Slider *******/
.hero-slide {
  width: calc(100% - 15px) !important;
  overflow: hidden;
  border-radius: 7.5px;
  margin: 0px 5px;
}
.hero-slide img {
  width: 100%;
}
.hero-slider {
  margin-bottom: 40px;
}
/* the slides */
.hero-slider .slick-slide {
  /* margin: 0 10px; */
}
/* the parent */
.hero-slider .slick-list {
  margin: 0 -7.5px;
}

.hero-slider .slick-dots li {
  width: auto;
  position: relative;
  height: auto;
}
.hero-slider .slick-dots li button {
  padding: 0px;
  text-align: center;
  width: auto;
  height: auto;
  position: relative;
}
.hero-slider .slick-dots li button::before {
  color: #fff;
  font-size: 12px;
  opacity: 1;
  position: relative;
}

.hero-slider .slick-dots li.slick-active button::before {
  color: #ff3002;
}



.audiobook-single-category .slick-list {
  margin: 0px -7.5px;
}
.audiobook-single-category .audiobook-slide {
  width: calc(100% - 15px) !important;
  overflow: hidden;
  border-radius: 10px;
  margin: 0px 7.5px;
}

.audiobook-single-category {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 30px;
}
.audiobook-category-title {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.audiobook-category-title h2 {
  color: #fff;
  font-size: 26px;
}
.audiobook-category-title a {
  font-size: 16px;
  color: #ff3002;
  text-decoration: none;
}
.audiobook-card {
  width: 100%;
  height: auto;
  float: left;
  padding:10px;
  background: rgba(124, 124, 124, 0.15);
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 20px;
}
.audiobook-image {
  width: 100%;
  /* height: auto; */
  float: left;
  border-radius: 8px;
  overflow: hidden;
}
.audiobook-image img{
  width: 100%;
}
.audiobook-content {
  width: 100%;
  height: auto;
  float: left;
  padding-top: 20px;
}
.audiobook-content a{
  font-size: 16px;
  color:#fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
  /* font-family: Poppins-SemiBold; */
}
.audiobook-content h6{
  font-size: 12px;
  color:#fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-family: Poppins-SemiBold; */
}
/* .footer-section {
  width: 100%;
  height: auto;
  float: left;
  background: #181818;
} */
.footer-section {
  width: calc(100% + 75px);
  height: auto;
  float: left;
  background: #181818;
  margin-left: -25px;
  margin-right: -50px;
}
.footer-menu-section{
  width: 100%;
  height: auto;
  float: left;
  padding: 30px 20px 10px 20px;
}
.footer-section ul {
  width: 100%;
  height: auto;
  float: left;
  padding: 0px;
  list-style-type: none;
}
.footer-section ul li a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
}
.footer-section h3 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
}
.footer-follow-us li {
  display: inline-block;
  padding-right: 15px;
}
.footer-follow-us li:last-child {
  padding-right: 0px;
}
.footer-follow-us li a {
  display: inline-block;
}
.footer-section .footer-menu li {
  padding-bottom: 7px;
}
.footer-section ul li a svg {
  max-width: 22px;
}
.footer-section ul li a:hover svg path.youtube {
  fill: #FF0000;
}
.footer-section ul li a:hover svg path.instagram {
  fill: #8a3ab9;
}
.footer-section ul li a:hover svg path.facebook {
  fill: #4267B2;
}
.footer-section ul li a:hover svg path.twitter {
  fill: #00acee;
}
.footer-download li {
  display: inline-block;
  padding-right: 10px;
}
.footer-download li:last-child{
  padding: 0px;
}
.footer-download li a {
  display: block;
}
.copyright {
  width: 100%;
  height: auto;
  float: left;
  padding: 20px;
  border-top: solid 1px #2C1E3A;
}
.copyright p {
  text-align: center;
  color: #fff;
  font-size: 13px;
  margin: 0px;
}


/************** Story Detail Page *********************/

.story-preview {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  background: rgba(255, 185, 209, 0.15);
  border-radius: 8px;
  padding:30px 30px 150px 30px;
  margin-bottom: 30px;
}
.story-preview .story-img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -65%);
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.story-preview .story-img img {
  max-width: 100%;
  max-height: 100%;
}
.story-preview .overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(254, 0, 88, 0.4);
  border-radius: 8px;
}
.story-preview .playbtn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
}
.story-preview .music-icon {
  width: 100%;
  text-align: center;
}
.story-preview .music-icon svg {
  max-width: 100%;
  max-height: 400px;
}
.story-preview-detail h2 {
  font-size: 24px;
  color: #ff3002;
}
.story-preview-detail ul {
  padding: 0px;
  list-style-type: none;
}
.story-preview-detail ul li {
  color: #fff;
  font-size: 13px;
  min-width: 100px;
  display: inline-block;
}
.story-preview-detail ul li .startIcon svg {
  max-width: 14px;
}
.story-disc p {
  font-size: 15px;
  color: #fff;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.story-disc .story-buttons{
  width: 100%;
  height: auto;
  float: left;
  margin-top: 30px;
}
.story-disc .story-buttons ul li a, .story-disc .story-buttons ul li button,
.playlist-profile-buttons .playbtn{
  background: #4B4B4B;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  min-height: 40px;
  border-radius: 4px;
  float: left;
  border: none;
}
.story-disc .story-buttons ul li a:hover, .story-disc .story-buttons ul li button:hover,
.playlist-profile-buttons .playbtn:hover {
  background: #ff3002;
  color: #fff!important;
}
.story-disc .story-buttons ul li {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
}
.story-disc .story-buttons ul li:last-child {
  margin-right: 0px;
}
.story-disc .story-buttons ul li a svg, .story-disc .story-buttons ul li button svg {
  /* max-width: 18px;
  max-height: 18px; */
  margin-right: 10px;
}
.story-preview-detail {
  width: 100%;
  height: auto;
  float: left;
  padding-top: 20px;
  margin-bottom: 30px;
}
.story-episodes-list {
  width: 100%;
  height: auto;
  float: left;
  padding-top: 30px;
  padding-bottom: 30px;
}
.story-episodes-list .episode-title, .similar-story-list .similar-story-title {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 20px;
}
.story-episodes-list .episode-title h3, .similar-story-list .similar-story-title h3{
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}
.similar-story-list {
  width: 100%;
  height: auto;
  float: left;
  padding-bottom: 50px;
}
.AudioPlayer {
  width: 100%;
  height: auto;
  float: left;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 20px;
  /* background: rgba(255, 185, 209, 0.5); */
  background: rgba(255, 185, 209, 0.5);
  border-radius: 8px;
}
.AudioPlayer .react-audio-player {
  width: 100%;
}
.total-download-likes {
  width: 100%;
  height: auto;
  float: left;
}
.total-download-likes ul {
  padding: 0px;
}
.total-download-likes ul li {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  margin-right: 60px;
  margin-bottom: 15px;
}
.total-download-likes ul li:last-child{
  margin-right: 0px;
}
.total-download-likes ul li svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.AudioPlayer .rhap_container {
  background: transparent;
  box-shadow: none;
}
.AudioPlayer .rhap_progress-bar-show-download {
  background-color: #FFDADA;
}
.AudioPlayer .rhap_container svg {
  vertical-align: initial;
  color: #fff;
}
.AudioPlayer .rhap_progress-indicator {
  background: #ff3002;
}
.rhap_play-pause-button svg {
  background: #fff;
  border-radius: 50%;
}
.rhap_play-pause-button svg path {
  fill: #ff3002;
}
.AudioPlayer .rhap_time {
  color: #fff;
}
.AudioPlayer .rhap_volume-indicator {
  background: #ff3002;
}
.AudioPlayer .rhap_volume-bar {
  background: #ffdada;
}

/**** Contact Us Page ****/
.contactus-page-section {
  width: 100%;
  height: auto;
  float: left;
  padding-bottom: 50px;
}
.contactform {
  width: 100%;
  height: 100%;
  float: left;
  background: #ff3002;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.06);
  padding: 60px 25px;
}
.contactus-content {
  width: 100%;
  height: 100%;
  float: left;
  padding: 50px 25px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
}
.contactus-content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #3e021785;
}
.contactinfo{
  width: 100%;
  height: auto;
  float: left;
  position: relative;
}
.contactinfo h1 {
  font-size: 48px;
  color: #ff3002;
  font-family: Poppins;
  font-weight: 700;
}
.single-contact {
  width: 100%;
  height: auto;
  float: left;
}
.single-contact h4 {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 20px;
  line-height: 136%;
  color: #FFFFFF;
}
.single-contact p {
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 16px;
  line-height: 136%;
  color: #FFFFFF;
}
.contactform h1 {
  font-size: 42px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 40px;
  font-family: Poppins;
  font-weight: 700;
}
.contactform .form-group{
  margin-bottom: 30px;
}
.contactform input, .contactform input:active, .contactform input:focus {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);;
  border-radius: 0px;
  color: #fff;
  box-shadow: none;
  height: 40px;
  font-size: 14px;
}
.contactform input::placeholder, .contactform textarea::placeholder{
  color: #fff;
}
.contactform textarea, .contactform textarea:active, .contactform textarea:focus {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);;
  border-radius: 0px;
  color: #fff;
  box-shadow: none;
  min-height: 120px;
  font-size: 14px;
}
.contactform .btn-primary, .contactform .btn-primary:hover, .contactform .btn-primary:focus {
  background: #FFBFD5;
  border: 1px solid #ff3002;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 160px;
  padding: 9px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  color: #ff3002;
}

/**** About Us Page *****/

.aboutus-page-section {
  width: 100%;
  height: auto;
  float: left;
  padding-bottom: 50px;
}
.aboutus-page-section h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ff3002;
  margin-bottom: 20px;
}

.aboutus-page-section p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  margin-bottom: 20px;
}
.aboutus-page-section .quote {
  width: 100%;
  height: auto;
  float: left;
  background: rgba(255, 97, 152, 0.16);
  border-radius: 4px;
  padding: 20px 25px;
  color: #fff;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  border-left: solid 6px #ff3002;
  margin-bottom: 20px;
}
.about-img {
  width: 100%;
  height: auto;
  float: left;
  border-radius: 10px;
  overflow: hidden;
}
.about-img img{
  width: 100%;
}
.about-content{
  width: 100%;
  height: auto;
  float: left;
  padding-top: 30px;
}
.about-content h3 {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 24px;
  color: #FFFFFF;
  margin-bottom: 15px;
}


/**************** Download App ********************/

.about-app-downloads{
  margin-top: 24px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  background-image: linear-gradient(to top, rgb(204, 240, 255), rgb(205, 204, 255));
  border-radius: 4px;
}
.app-downloads-title{
  margin-top: 8px; margin-left: 8px;
}
.app-downloads-title h4{font-size: 20px; font-weight: bold; color: rgba(13, 21, 54, 0.8);}
.app-downloads-title h5{font-size: 14px; color: rgba(13, 21, 54, 0.4);}
.about-app-stories{
  margin-top: 24px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  border-radius: 4px;
  align-items: center;
  background-image: linear-gradient(to top, rgb(252, 155, 203), rgb(254, 105, 157));
}
.download-app-story-image {
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
}
.download-app-story-image  img{
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
}
.download-app-content {
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
  padding-top: 20px;
}
.download-app-content h1 {
  font-size: 24px;
  font-weight: bold;
  color: #ff3002;
}
.download-app-content h4 {
  font-size: 16px;
  color: #666;
}
.DownloadApp .modal-dialog {
  max-width: 380px;
  margin: 0 auto;
}
.download-app-btn, .download-app-btn:hover, .download-app-btn:focus, .download-app-btn:active {
  background: #ff3002!important;
  border: none!important;
  width: 100%;
  height: 45px;
  font-size: 18px!important;
  margin-top: 20px;
}
.page-not-dund-img {
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
}

.page-not-dund-img img {
  width:100%;
  max-width: 500px;
}
.backtohome, .backtohome:hover {
  padding: 10px 20px;
  display: inline-block;
  background: #ff3002;
  color: #fff!important;
  text-decoration: none;
  border-radius: 4px;
  min-width: 160px;
  font-size:14px;
  text-align: center;
}

 /****** Register/Login Page *******/
 .main-btn{
  background: #ff3002!important;
  border: 1px solid #ff3002!important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)!important;
  border-radius: 4px;
  min-width: 160px;
  font-size: 15px!important;
  color: #fff;
 }

 .create-account-section {
  width: 100%;
  min-height:100%;
  position: relative;
  display: flex;
  align-items: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.create-account-section .create-account-bg {
  width: calc(100% + 75px);
  height: calc(100vh - 78px);
  object-fit: cover;
  position: absolute;
  margin: -30px -50px 0px -25px;
  left: 0;
  top: 0;
}
.createaccount-content {
  width: 100%;
  max-width:900px;
  /* min-height: calc(100% - 25px); */
  /* padding: 30px; */
  border-radius: 8px;
  background: #2d2d2dd9;
  position: relative;
  z-index: 1;
  margin:0 auto!important;
}
/* .createaccount-content{
  width: 100%;
  height: auto;
  padding: 0px;
} */
.createaccount-content label.form-label {
  color: #fff;
  font-size: 14px;
}
.createaccount-content input[type="text"], .createaccount-content input[type="date"], .createaccount-content input[type="email"], .createaccount-content input[type="password"], .createaccount-content input[type="number"]{
  width: 100%;
  height: 40px;
  font-size: 13px;
  background: #ffffff3d;
  border-color: #ffffff08;
  color: #fff;
}
.createaccount-content input::placeholder{
  color: rgba(255, 255, 255, 0.562);
}

.createaccount-content form{
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 130px);
  overflow: auto;
  padding: 30px;
}
.create-account-section h2{font-size: 32px; font-weight: bold; color: #ff3002;}
.create-account-section p.info{font-size: 14px; color: #fff; margin-bottom: 25px;}
.create-account-section .form-check {color: #fff; font-size: 13px;}
.form-check-input:checked {
  background-color: #ff3002!important;
  border-color: #ff3002!important;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
  width: 15px;
  height: 15px;
  border: none;
}
.donthaveaccount p {
  font-size: 16px;
  color: #fff;
}
.donthaveaccount p a {
  color: #ff3002;
}
.login-with-socialmedia-section {
  border-right: solid 1px #646464;
}
.login-with-socialmedia{
  width: 100%;
  height: auto;
  padding: 30px;
}
.login-with-socialmedia ul li{margin-bottom: 20px;}
.login-with-socialmedia ul li a {
  display: block;
  padding: 8px 15px;
  /* background: #eee; */
  border-radius: 4px;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
}
.login-with-socialmedia ul li a:hover {
  color: #fff!important;
  opacity: 0.8;
}
.login-with-socialmedia ul li a img {
  max-width: 20px;
  max-height: 20px;
  margin-right: 10px;
}
.facebook{background: #4267B2;}
.twitter{background: #1DA1F2;}
.instagram{background: #3f729b;}
.linkedin{background: #0077b5;}
.google{background: #4285F4;}

.otp-inputs {
    width: 100%;
    justify-content: space-between;
}
.otp-inputs input {
  margin: 0px!important;
  width: 40px!important;
  height: 40px!important;
  border-radius: 4px;
}
.resend-otp {
  margin-top: 15px;
}
.resend-otp span {
  font-size: 13px;
  color: #fff;
}
.resend-otp button {
  background: #FFBFD5;
  border: 1px solid #ffadc9;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 10%);
  border-radius: 4px;
  min-width: 110px;
  padding: 9px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 13px;
  color: #ff3002;
}
.resend-otp button:hover {
  background: #ff3002;
  border: 1px solid #ff3002;
  color: #FFBFD5;
}


/**** Blog List Page ****/
.epcl-dropcap {
  display: block;
  font-size: 80px;
  color: #fff;
  background: var(--epcl-main-color);
  width: 100%;
  height: 240px;
  font-family: "Josefin Sans",sans-serif;
  text-align: center;
  line-height: 1;
  font-weight: 700;
  float: left;
  margin-right: 30px;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-shadow: 0 3px 5px -2px rgb(7 10 25 / 20%);
  box-shadow: 0 3px 5px -2pxrgba(7,10,25,.2);
  text-transform: uppercase;
}
.post-format-image .thumb {
  position: relative;
  z-index: 1;
  min-height: 80px;
}
.post-format-image a.thumb {
  display: block;
  position: relative;
}
.post-format-image .epcl-dropcap:before {
  content: '';
  border: 1px solid #fff;
  position: absolute;
  left: 3px;
  top: 3px;
  right: 3px;
  bottom: 3px;
  z-index: 5;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
.post-format-image a.epcl-dropcap.main-effect:not(.text-only).loaded:before {
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  z-index: 2;
  background: #fff;
}
.post-format-image .thumb .fullimage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%!important;
  z-index: 1;
}
.post-format-image a.thumb span {
  top: 0;
  left: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  z-index: -1;
  margin: 0;
}
.post-format-image a.epcl-dropcap.main-effect .fake-layout {
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
  right: auto!important;
  bottom: auto!important;
  z-index: 4!important;
}
.post-format-image a.epcl-dropcap.main-effect .fake-layout:before {
  content: '';
  border: 1px solid #fff;
  position: absolute;
  left: 3px;
  top: 3px;
  right: 3px;
  bottom: 3px;
  z-index: 5;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
.post-format-image .cover {
  background-repeat: no-repeat!important;
  background-position: center center!important;
  background-size: cover!important;
}
.post-format-image a.epcl-dropcap:hover:before {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.post-format-image a.epcl-dropcap:hover .fake-layout {
  left: -8px!important;
  top: -8px!important;
}
.post-format-image a.epcl-dropcap:hover .fake-layout:before {
  opacity: 0;
}
.post-format-info .post-title {
  font-size: 18px;
  color:#fff;
  margin-top: 10px;
  text-decoration: none;
}
.ellipsis-2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis-3{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.meta {
  width: 100%;
  height: auto;
  float: left;
}
.meta .meta-info {
  margin-right: 12px;
  white-space: nowrap;
  font-size: 12px;
  color:#fff;
  display: inline-block;
}
.meta .min-read {
  display: inline-block;
  cursor: default;
  text-transform: lowercase;
}
.meta .min-read:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 2px;
  background: #ff3002;
  vertical-align: middle;
  margin-right: 15px;
}
.meta .meta-info img {
  max-width: 14px;
  max-height: 14px;
  margin-right: 4px;
}
a.comments.meta-info {
  color: #fff;
  text-decoration: none;
}
.post-format-info p {
  width: 100%;
  float: left;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}

.blog-articles.small-blog .post-format-image {
  display: flex;
}
.blog-articles.small-blog .post-format-image a.thumb {
  width: 60px;
  height: 64px;
  min-width: 60px;
  min-height: 64px;
}
.blog-articles.small-blog .post-format-info a {
  font-size: 14px;
}

/* ///////// Common Modal /////////// */
.common-modal.offcanvas {
  background-color: #232323;
  padding: 0px;
}
.common-modal .modal-title {
  font-size: 16px;
  text-transform: uppercase;
}
.common-modal.offcanvas .offcanvas-body{
  padding-left: 25px;
  padding-right: 25px;
}
.common-modal textarea{
  min-height: 100px!important;
}

.common-modal .create-playlist-form .saveBtn{
  width: 100%;
  text-align: center;
  background: #4B4B4B;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 20px;
  min-height: 40px;
  border-radius: 4px;
  border: none;
}
.common-modal .create-playlist-form .saveBtn:hover {
  background: #ff3002;
  color: #fff!important;
}
.modal-title{
  font-size: 16px;
  color: #ff3002;
  font-weight: bold;
  margin-bottom: 20px!important;
  display: block;
}
.all-playlist{
  width: calc(100% + 50px);
  margin-top: 30px;
  margin-left: -25px;
  margin-right: -25px;
  border-top: solid 1px #4B4B4B;
  padding: 30px 25px 0px 25px;
}
.all-playlist ul{
  padding: 0;
}
.all-playlist ul li:not(:last-child){
  margin-bottom: 15px;
}
.single-playlist-item{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  background: #4B4B4B;
  padding: 5px 5px;
  border-radius: 5px;
  transition: all 0.5s;
}
.single-playlist-item:hover{
  background: #303030;
  cursor: pointer;
}
.playlist-img{
  flex: 0 0 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: solid 2px #4b4b4b;
  padding: 2px;
}
.playlist-img img{
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.playlist-title{
  width: 100%;
}
.playlist-title h5{
  font-size: 16px;
  color: #fff;
}
.playlist-title p{
  font-size: 12px;
  color: #fff;
  margin-bottom: 0;
}


.common-dropdown .dropdown-toggle, .common-dropdown .dropdown-toggle:hover, .common-dropdown .dropdown-toggle:focus{
  color: #fff;
  box-shadow: none;
  outline: none;
}
.common-dropdown .dropdown-menu {
  box-shadow: 0 0 12px 4px rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  min-width: 200px;
}
.common-dropdown .dropdown-menu a{
  color: #181818;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
}
.common-dropdown .dropdown-menu a:hover,
.common-dropdown .dropdown-menu a:focus,
.common-dropdown .dropdown-menu a:active
{
  color: #fff!important;
  background: #ff3002;
}

.playlist-profile-buttons {
  width: 100%;
  display: inline-flex;
  gap: 20px;
}
.playlist-profile-buttons .common-dropdown button {
  background: #4b4b4b;
  width: 43px;
  height: 43px;
  padding: 5px;
}
.playlist-profile-buttons .common-dropdown button:hover,
.playlist-profile-buttons .common-dropdown button:focus,
.playlist-profile-buttons .common-dropdown button:active
{
  color: #fff!important;
  background: #ff3002;
}
.playlist-profile-buttons .common-dropdown button svg{
  max-width: 20px;
  max-height: 12px;
}
.playlist-profile-buttons .common-dropdown button:after{
  display: none;
}

.playlist-profile-buttons .playbtn{
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}
.playlist-profile-buttons .playbtn svg{
  max-width: 20px;
  max-height: 15px;
}
.change-password-section .createaccount-content {
  max-width: 700px;
}


.choose-mood-section h2 {
  color: #fff;
  font-size: 26px;
}
.choose-mood-section p {
  color: #fff;
  font-size: 16px;
}
.choose-mood-list{
  width:100%;
  height: auto;
  display: inline-block;
  margin: 20px 0px;
  padding:30px 20px;
  border-radius: 8px;
  background: #2d2d2dd9;
}
.choose-mood-list ul{
  width: 100%;
  margin: 0px;
  padding: 0;
  display: inline-flex;
  gap: 20px;
  flex-wrap: wrap;
}
.choose-mood-list ul li{
  display: inline-block;
  list-style-type: none;
  gap: 20px;
}

.choose-mood-list ul .togglebtn input+label{
  background: #4b4b4b;
  padding: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  outline: none;
}
.choose-mood-list ul .togglebtn input+label:hover,
.choose-mood-list ul .togglebtn input:checked+label:hover,
.choose-mood-list ul .togglebtn input:checked+label:focus,
.choose-mood-list ul .togglebtn input:checked+label:active
{
  color: #fff!important;
  background: #ff3002;
}

.togglebtn input:checked+label{
  background: #ff3002!important;
}

.for-kids, .for-kids input, .for-kids label{
  cursor: pointer;
}
.for-kids input{
  width: 2.1rem!important;
  background-color: #232323;
  background-image: url('data:image/svg+xml,<svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="51" cy="51" r="51" fill="white" fill-opacity="0.25"/></svg>')!important;
  background-size: 18px!important;
  background-position: left 3px center!important;
}
.for-kids input:checked{
  background-position: right 3px center!important;
  background-image: url('data:image/svg+xml,<svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="51" cy="51" r="51" fill="white" fill-opacity="0.75"/></svg>')!important;
}

.for-kids .form-check-label {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.for-kids .form-check-input {
  width: 2rem;
  height: 1.3rem;
  border-radius: 20px;
}
.google-ad-250{
  width: 100%;
  max-width: 250px;
  display: block;
  margin: 15px auto 15px auto;
}
.google-ad-250 a{
  display: inline-block;
}
.google-ad-250 img{
  max-width: 100%;
}
.google-ad-780{
  width: 100%;
  max-width: 780px;
  display: block;
  margin: 15px auto 15px auto;
}
.google-ad-780 a{
  display: inline-block;
}
.google-ad-780 img{
  max-width: 100%;
}

.multiselect-filter {
  flex-flow: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
.multiselect-filter .single-filter {
  width: auto;
  display: flex;
  color: #818181;
  text-decoration: none;
  border: solid 1px #575757;
  border-radius: 4px!important;
  justify-content: center;
  transition: all 0.3s;
}
.multiselect-filter .btn-check+.single-filter:hover,
.multiselect-filter .btn-check:checked+.single-filter {
  color: #fff;
  background: #ff3002;
  border-color: #ff3002;
}
.offcanvas.filter-offcanvas {
  background: #323232;
  color: #fff;
}
.filter-offcanvas .btn-close{
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat!important;
}
.filter-offcanvas .offcanvas-header{
  border-bottom: solid 1px #575757;
  padding: 20px;
}
.filter-offcanvas .offcanvas-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
}
.search-filter-body {
  padding: 20px;
}
.search-filter-body h4 {
  width: 100%;
  font-size: 16px;
  color: #ff3002;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 15px;
}
.header-part .dropdown-menu {
  background: #323232;
}
.search-filter-body {
  padding: 20px;
  height: auto;
  max-height: calc(100vh - 148px);
  overflow: auto;
}
/*********** Responsive Css ************/

@media(min-width: 992px){
  .header-part .top-menu-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pro-sidebar.siderbar-menu{
    display: none;
  }
}
@media(max-width: 992px){
  .menu {
    display: none;
  }
  .logo-mobile{display: block;}
  .header-part{height: auto; padding: 10px 15px;}
  .header-part .searchbar {
    max-width: calc(100% - 70px);
    margin-top: 15px;
    float: left;
}
.menu-user-profile {
  width: auto;
  float: right;
  margin-top: 10px;
}
  .header-part .searchbar input, .header-part .searchbar .search-btn {
    height: 40px;
  }
  .header-part .humbuerger{
    display: block;
  }
  .header-part .humbuerger svg {
    width: 40px;
    height: 40px;
  }
  .pro-sidebar.siderbar-menu{
    display: none;
  }
  .pro-sidebar.siderbar-menu.active {
    display: block;
    position: fixed;
    z-index: 999;
    top: 0px;
    left: 0px;
    width: calc(100% - 40px);
    max-width: 400px;
    min-width: auto;
    height: 100vh;
  }
  .pro-sidebar.siderbar-menu.active::before {
    content: "";
    position: fixed;
    left: 0px;
    top: 0px;
    /* width: calc(100% + 10%); */
    width: 100%;
    height: 100%;
    background: #000000ba;
  }
  .logo-hemburger {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main-content {
    max-height: calc(100vh - 119px);
    padding: 30px 25px 0px 25px;
  }
  .siderbar-menu .close-btn {
    display: block;
    background: #ff3002;
    width: 40px;
    height: 40px;
    border: none;
    right: 0;
    top: 0;
    position: absolute;
  }
  .siderbar-menu .close-btn svg {
    max-width: 30px;
    max-height: 30px;
  }

  /** Login/Register ****/
  .create-account-section .create-account-bg {
    /* width: calc(100% + 50px); */
    width:100%;
    height: 100%;
    margin: 0px!important;;
  }
  .create-account-section {
    /* height: calc(100vh - 140px); */
    /* overflow: auto; */
}
.create-account-section {
  height: calc(100% + 30px);
  margin: -30px -50px 0px -25px!important;
  width: calc(100% + 45px);
  padding: 20px;
}
.main-wrapper {
  min-height: calc(100vh - 119px);
}
.playlist-profile .playlist-profile-img {
  flex: 0 0 150px;
  height: 150px;
}
}
@media (max-width: 768px){
  /* .header-part {
    padding: 0px 15px;
  } */
  /* .header-part .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  } */
  .footer-section {
    width: calc(100% + 50px);
  }
  .contactinfo h1 {
    font-size: 42px;
  }
  .contactform h1 {
    font-size: 34px;
  }
  .contactform {
  padding: 50px 20px;
  }
  .contactform h1 {
    margin-bottom: 30px;
  }

/** Login/Register ****/
  .login-with-socialmedia-section {
    border-top: solid 1px #646464;
    border-right: none;
  }
  .create-account-section {
    align-items: start;
}

.song-album-info{
  width: 100%;
  display: block;
}

}

@media(max-width: 576px){
  .hero-slider .slick-dots li button::before {
    font-size: 8px;
  }
  .hero-slider .slick-dots li {
    margin: 0 2px;
  }
  .main-content {
    padding: 30px 15px 0px 15px;
  }
  .footer-section {
    width: calc(100% + 15px);
    margin-left: -15px;
    position: relative;
  }
  .audiobook-single-category {
    margin-bottom: 10px;
  }
  .story-preview .story-img {
    transform: translate(-50% , -70%);
  }
  .total-download-likes ul li {
    font-size: 15px;
    margin-right: 20px;
  }
  .contactus-content {
    padding: 30px 15px;
  }
  .contactform {
    padding: 30px 15px;
  }
  .contactus-page-section .container {
    padding: 0px;
  }
  .remove-from-list{
    display: none;
  }
  .playlist-song-img {
    flex: 0 0 60px;
    height: 60px;
  }
}
